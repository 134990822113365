import * as React from 'react';

interface SvgProps extends React.SVGProps<SVGSVGElement> {
  className?: string; // Add className prop
  textColor?: string; // Add textColor prop
  [key: string]: any;
}

const Circle: React.FC<SvgProps> = ({ className, textColor = 'text-white', ...props }: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25"
    fill="none"
    className={className || ''}
    {...props}
  >
    <path
      className="fill-shapes-path"
      d="M0 12.5C0 5.6 5.58 0 12.47 0c3.31 0 6.48 1.32 8.82 3.66s3.65 5.52 3.65 8.84c0 6.9-5.58 12.5-12.47 12.5S0 19.4 0 12.5"
    />
  </svg>
);

export default Circle;
