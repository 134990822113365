import React from 'react';
import { ChevronRight as ChevronRightIcon } from 'lucide-react';

interface ArrowRightProps {
  width?: string;
  xlWidth?: string;
  leftAlign?: boolean; // Optional prop for left alignment
}

export const ArrowRight: React.FC<ArrowRightProps> = ({
  width = 'w-[36px]',
  xlWidth = 'xl:w-[36px]',
  leftAlign = false, // Default is not left-aligned
}) => {
  return (
    <div
      className={`${width} h-auto ${xlWidth} ${leftAlign ? 'text-left' : ''}`}
    >
      <ChevronRightIcon
        className="w-full h-full stroke-primary fill-none"
        strokeWidth={2}
      />
    </div>
  );
};
