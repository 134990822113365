import * as React from 'react';

interface SvgProps extends React.SVGProps<SVGSVGElement> {
  [key: string]: any
}

const HalfsquareDown: React.FC<SvgProps> = (props: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25"
    // width={26}
    // height={25}
    fill="none"
    {...props}
  >
    <path
      className="fill-shapes-path"
      fillRule="evenodd"
      d="M25.67.03V12.5c0 6.89-5.6 12.47-12.5 12.47S.67 19.39.67 12.5V.03z"
      clipRule="evenodd"
    />
  </svg>
);

export default HalfsquareDown;
