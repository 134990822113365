import React, { SVGProps } from 'react';

const SvgSubmenuSingleIcon: React.FC<SVGProps<SVGSVGElement>> = ({
  width = 11, // Default width
  height = 18, // Default height
  className,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 13 22"
    className={`${className} fill-current`}
    {...props}
  >
    <path
      className={`fill-primary`}
      d="M2.34 21.61.22 19.49l8.54-8.54L.32 2.51 2.44.39 13 10.95z"
    />
  </svg>
);

export default SvgSubmenuSingleIcon;
