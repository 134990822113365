'use client';
import { SettingsPageLayoutHeaderLinks } from '@generated/types';
import SubMenuSingle from '@libs/ui/utils/SubMenuSingle';
import { useState } from 'react';
import colors from 'tailwindcss/colors';
import { tinaField } from 'tinacms/dist/react';
import { SafeLink } from '../safe-link';

export const MenuLink = (
  props: SettingsPageLayoutHeaderLinks & { index: number; path?: string },
) => {
  const { target, colorOverride, text, subMenuLinks, index } = props;

  const [hover, setHover] = useState(false);
  const [visibleMenu, setVisibleMenu] = useState<number | null>(null); // State to track which submenu is visible

  const isCurrent = `/${props?.path}`?.startsWith(target || '#');
  return (
    <div
      className={`menu-item text-text_color flex-shrink-0 text-center group ${index === 0 ? 'ml-auto' : 'mx-auto first:ml-0'}  last:mr-0`}
      onMouseLeave={() => setHover(false)}
    >
      <SafeLink
        href={target}
        style={colorOverride ? { color: colorOverride } : {}}
        data-tina-field={tinaField(props, 'text')}
        onMouseEnter={() => setHover(true)}
      >
        <span className="relative inline-block">
          {text?.replaceAll(' ', '\u00A0')}
          <span
            className={`absolute inset-x-0 bottom-0 h-[2px] transition-all duration-300 bg-transparent`}
            style={{
              backgroundColor:
                hover || isCurrent
                  ? colorOverride || colors.current
                  : 'transparent',
            }}
          ></span>
        </span>
      </SafeLink>
      {subMenuLinks && subMenuLinks?.length > 0 && (
        <div
          data-hover={hover}
          className={`absolute transition-all data-[hover=true]:opacity-100 opacity-0 data-[hover=false]:pointer-events-none data-[hover=false]:select-none`}
        >
          <SubMenuSingle
            data={subMenuLinks}
            {...{ visibleMenu, setVisibleMenu, path: props?.path }}
          />
        </div>
      )}
    </div>
  );
};
