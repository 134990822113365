interface CaretDownProps {
  color: string; // Tailwind color class (e.g., "fill-black")
  className?: string; // Additional utility classes for responsive display
}

export const CaretDown = ({ color, className }: CaretDownProps) => {
  return (
    <div className={`${className}`}>
      <svg
        width="23"
        height="14"
        viewBox="0 0 23 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.961641 2.72937L3.08164 0.609374L11.6216 9.14937L20.0616 0.709375L22.1816 2.82938L11.6216 13.3894L0.961641 2.72937Z"
          className={color} // Use the passed color prop
        />
      </svg>
    </div>
  );
};
