import React from 'react';

export const Rectangle = () => {
  return (
    <div className="w-[16px] h-auto xl:w-[18px]">
      <svg width="3" height="3" viewBox="0 0 3 3" fill="#FF0000 !important" xmlns="http://www.w3.org/2000/svg">
        <rect width="3" height="3" className="fill-shapes-path" background-color="#FF0000 !important" />
      </svg>
    </div>
  );
};
