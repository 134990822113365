import { Site } from '@apps/site';
import {
  ArticleQueryQuery,
  GlobalPartsFragment,
  PageQueryQuery,
  SettingsPartsFragment,
} from '@generated/types';
import {
  CaretDown,
  Facebook,
  Instagram,
  LinkedIn,
  Rectangle,
  YouTubeIcon,
} from '@libs/ui/utils/Templates/Shapes';
import { useState } from 'react';
import { tinaField } from 'tinacms/dist/react';
import { RecursiveOmit } from '../../Blocks/AnimationText';
import { SafeLink } from '../../utils/safe-link';
import HubSpotSubscribeForm from './subscribe-form';

export default function Footer({
  settings,
  data,
  path,
  site,
}: {
  global: RecursiveOmit<GlobalPartsFragment, '__typename'>;
  settings: RecursiveOmit<SettingsPartsFragment, '__typename'>;
  data?: RecursiveOmit<
    ArticleQueryQuery['article'] | PageQueryQuery['page'],
    '__typename'
  >;
  path?: string;
  site: Site;
  pageId?: string;
}) {
  return (
    <div
      className={`container p-0 pt-[30px] pb-[170px] w-full contentGrid !block lg:!grid border-t-2 border-shade20`}
    >
      <MobileSections settings={settings} data={data} />
      <SubscribeSection
        settings={settings}
        data={data}
        path={path}
        site={site}
      />
      <DesktopSections settings={settings} data={data} />
    </div>
  );
}

Footer.blockName = 'LayoutFooterFooter';

const SubscribeSection = ({
  settings,
  data,
  path,
  site,
}: {
  settings?: RecursiveOmit<SettingsPartsFragment, '__typename'>;
  data?: RecursiveOmit<
    ArticleQueryQuery['article'] | PageQueryQuery['page'],
    '__typename'
  >;
  path?: string;
  site?: Site;
}) => {
  return (
    <div className="col-span-4 lg:col-span-1 xl:col-span-1 order-last lg:order-first caption-text light text-text-cosmetics flex flex-col justify-between">
      <div className="border-t-2 lg:border-t-0 border-shade20 flex flex-row items-center pb-1 mb-4 lg:pb-0 lg:mb-0 font-ultralight italic text-shade20 lg:mt-8">
        <HubSpotSubscribeForm
          settings={settings}
          data={data}
          path={path}
          site={site}
        />
      </div>
      <div className="addressOuter text-base border-b-2 lg:border-t-0 lg:border-b-0 p-4 border-shade20 lg:p-0 mt-2 lg:mt-0 text-[18px]">
        <span
          data-tina-field={tinaField(settings?.pageLayout?.footer, 'copyright')}
        >
          {settings?.pageLayout?.footer?.copyright}
        </span>
        <br />
        <span
          data-tina-field={tinaField(settings?.pageLayout?.footer, 'address')}
        >
          85 Hicks Avenue - Medford, MA 02155
        </span>
        <br />
        <span
          data-tina-field={tinaField(
            settings?.pageLayout?.footer,
            'footerLinks',
          )}
        >
          {settings?.pageLayout?.footer?.footerLinks?.map(
            (link, index: number) =>
              link ? (
                <span
                  key={`footer-link#${index}`}
                  data-tina-field={tinaField(link)}
                >
                  <SafeLink href={link?.link}>{link?.title}</SafeLink>
                  {(settings?.pageLayout?.footer?.footerLinks || [])?.length -
                    1 !==
                    index && <span> / </span>}
                </span>
              ) : (
                <span></span>
              ),
          )}
        </span>
      </div>
    </div>
  );
};

const MobileSections = ({
  settings,
}: {
  settings?: RecursiveOmit<SettingsPartsFragment, '__typename'>;
  data?: RecursiveOmit<
    ArticleQueryQuery['article'] | PageQueryQuery['page'],
    '__typename'
  >;
}) => {
  const columns = settings?.pageLayout?.footer?.columns;
  const socialMediaLinks = settings?.pageLayout?.footer?.socialMediaLinks;
  const [openSection, setOpenSection] = useState<string | undefined | null>(
    null,
  );

  const socialIcons = [
    socialMediaLinks?.instagram && (
      <SafeLink
        href={socialMediaLinks?.instagram}
        target={`_blank`}
        aria-label="Instagram of OPT Industries"
      >
        <Instagram
          color="fill-shapes-path"
          className={`h-[18px] text-right fill-shapes-path !z20`}
        />
      </SafeLink>
    ),
    socialMediaLinks?.linkedin && (
      <SafeLink
        href={socialMediaLinks?.linkedin}
        target={`_blank`}
        data-tina-field={tinaField(socialMediaLinks, 'linkedin')}
        aria-label="LinkedIn of OPT Industries"
      >
        <LinkedIn color="fill-shapes-path" className={`h-[18px] text-right`} />
      </SafeLink>
    ),
    socialMediaLinks?.youtube && (
      <SafeLink
        href={socialMediaLinks?.youtube}
        target={`_blank`}
        data-tina-field={tinaField(socialMediaLinks, 'youtube')}
        aria-label="Youtube of OPT Industries"
      >
        <YouTubeIcon
          color="fill-shapes-path"
          className={`h-[20px] text-right`}
        />
      </SafeLink>
    ),
    socialMediaLinks?.facebook && (
      <SafeLink
        href={socialMediaLinks?.facebook}
        target={`_blank`}
        data-tina-field={tinaField(socialMediaLinks, 'facebook')}
        aria-label="Facebook of OPT Industries"
      >
        <Facebook color="fill-shapes-path" className={`h-[18px] text-right`} />
      </SafeLink>
    ),
  ].filter(Boolean);

  return (
    <div className="lg:hidden">
      {columns?.map((column, index) => (
        <div
          key={index}
          className="grid grid-cols-8 w-full pb-6 pt-6 "
          data-tina-field={tinaField(column)}
        >
          <div className="col-span-6">
            <SafeLink
              href={column?.link}
              data-tina-field={tinaField(column, 'link')}
            >
              {column?.title}
            </SafeLink>
            <ul
              className={`list-none p-0 pt-4 transition-all duration-300 ease-in-out data-[open=true]:max-h-screen max-h-0 data-[open=false]:overflow-hidden`}
              data-open={openSection === column?.title}
            >
              {column?.links?.map((link: any, index: number) => (
                <li
                  key={index}
                  className="pl-0 pb-6 pt-6 flex flex-row items-baseline"
                >
                  <Rectangle />
                  <SafeLink href={link?.link}>{link.name}</SafeLink>
                </li>
              ))}
            </ul>
          </div>
          <div
            className={``}
            onClick={() =>
              setOpenSection(openSection === column?.title ? '' : column?.title)
            }
          >
            <CaretDown
              color="fill-shapes-path"
              className={`transition-transform w-[25px] h-[25px] data-[open=true]:rotate-90 data-[open=false]:pt-2`}
              data-open={openSection === column?.title}
            />
          </div>
          <div className={`flex justify-end !z-20`}>
            {index === 0 && socialIcons?.[0]}
            {index === 1 && socialIcons?.[1]}
            {index === 2 && socialIcons?.[2]}
            {index === 3 && socialIcons?.[3]}
          </div>
        </div>
      ))}
    </div>
  );
};

const DesktopSections = ({
  settings,
}: {
  settings?: RecursiveOmit<SettingsPartsFragment, '__typename'>;
  data?: RecursiveOmit<
    ArticleQueryQuery['article'] | PageQueryQuery['page'],
    '__typename'
  >;
}) => {
  const firstColumn = settings?.pageLayout?.footer?.columns?.[0];
  const genericColumns = settings?.pageLayout?.footer?.columns?.slice(1);
  const socialMediaLinks = settings?.pageLayout?.footer?.socialMediaLinks;

  const socialIcons = [
    socialMediaLinks?.instagram && (
      <SafeLink
        href={socialMediaLinks?.instagram}
        target={`_blank`}
        data-tina-field={tinaField(socialMediaLinks, 'instagram')}
      >
        <Instagram color="fill-shapes-path" className={`h-[18px] !z-50`} />
      </SafeLink>
    ),
    socialMediaLinks?.linkedin && (
      <SafeLink
        href={socialMediaLinks?.linkedin}
        target={`_blank`}
        data-tina-field={tinaField(socialMediaLinks, 'linkedin')}
      >
        <LinkedIn color="fill-shapes-path" className={`h-[18px] !z-50`} />
      </SafeLink>
    ),
    socialMediaLinks?.youtube && (
      <SafeLink
        href={socialMediaLinks?.youtube}
        target={`_blank`}
        data-tina-field={tinaField(socialMediaLinks, 'youtube')}
      >
        <YouTubeIcon color="fill-shapes-path" className={`h-[20px]`} />
      </SafeLink>
    ),
    socialMediaLinks?.facebook && (
      <SafeLink
        href={socialMediaLinks?.facebook}
        target={`_blank`}
        data-tina-field={tinaField(socialMediaLinks, 'facebook')}
      >
        <Facebook color="fill-shapes-path" className={`h-[18px]`} />
      </SafeLink>
    ),
  ].filter(Boolean);

  return (
    <>
      <div className="hidden lg:block lg:col-span-1">
        <div className="flex flex-col w-auto font-normal">
          <div className={`flex flex-row items-center justify-between pb-4`}>
            <div>
              <SafeLink
                href={firstColumn?.link}
                data-tina-field={tinaField(firstColumn, 'title')}
              >
                {firstColumn?.title}
              </SafeLink>
            </div>

            <div
              className=""
              data-tina-field={tinaField(socialMediaLinks, 'instagram')}
            >
              {socialIcons?.[0]}
            </div>
          </div>
          <ul
            className={`list-none p-0 pl-0 transition-all duration-300 ease-in-out max-h-screen`}
          >
            {firstColumn?.links?.map((link, index: number) => (
              <li
                key={index}
                className="pl-0 pb-6 last:pb-0 pt-6 flex flex-row items-center justify-between"
                data-tina-field={tinaField(link)}
              >
                <div className={`flex flex-row items-baseline`}>
                  {'.'}
                  <div className={`mr-3.5 !font-extralight`}></div>
                  <SafeLink
                    href={link?.link}
                    data-tina-field={tinaField(link, 'name')}
                  >
                    {link?.name}
                  </SafeLink>
                </div>
                <div data-tina-field={tinaField(socialMediaLinks)}>
                  {index === 0 && socialIcons?.[1]}
                  {index === 1 && socialIcons?.[2]}
                  {index === 2 && socialIcons?.[3]}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="hidden lg:flex lg:col-span-2 flex-row justify-between mr-[-34px]">
        {genericColumns?.map((column, index) => (
          <div
            className="xl:pr-[40px] w-auto p-0 font-normal decoration-2"
            key={index}
          >
            <div className={`pb-4`}>
              <SafeLink
                href={column?.link ?? '#'}
                data-tina-field={tinaField(column, 'title')}
              >
                {column?.title}
              </SafeLink>
            </div>
            <ul
              className={`list-none p-0 pl-0 transition-all duration-300 ease-in-out max-h-screen`}
            >
              {column?.links?.map((link, index: number) => (
                <li
                  key={`technologyColumn-link#${index}`}
                  className="pl-0 pb-6 last:pb-0 pt-6 flex flex-row items-baseline"
                  data-tina-field={tinaField(link)}
                >
                  {'.'}
                  <div className={`mr-3.5 !font-extralight`}></div>
                  <SafeLink
                    href={link?.link}
                    data-tina-field={tinaField(link, 'name')}
                  >
                    {link?.name}
                  </SafeLink>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </>
  );
};
