import { Shape } from '@libs/ui/utils/enums';
import Circle from '@libs/ui/utils/Templates/Shapes/Circle';
import HalfsquareDown from '@libs/ui/utils/Templates/Shapes/HalfsquareDown';
import HalfsquareLeft from '@libs/ui/utils/Templates/Shapes/HalfsquareLeft';
import HalfsquareRight from '@libs/ui/utils/Templates/Shapes/HalfsquareRight';
import HalfsquareUp from '@libs/ui/utils/Templates/Shapes/HalfsquareUp';
import Square from '@libs/ui/utils/Templates/Shapes/Square';
import { Fragment } from 'react';

type ShapeOption = Shape | string | null | undefined;

type ShapesComponentProps = {
  shapeOptions: {
    left?: ShapeOption;
    middle?: ShapeOption;
    right?: ShapeOption;
  };
  shapeBoxshadow?: boolean;
  textColor?: string;
};

export const shapeClassName =
  'w-[16px] h-[16px] md:w-[15px] md:h-[15px] lg:w-[25px] lg:h-[25px] xl:w-[25px] xl:h-[25px] flex-shrink-0 !z-20';

export default function ShapesComponent({
  shapeOptions,
  textColor = 'text-white',
}: ShapesComponentProps) {
  return (
    <div className="shapesOuter flex h-auto">
      {/* Left Shapes */}
      <SingleShapeComponent
        shape={shapeOptions?.left as any}
        textColor={textColor}
      />

      {/* Middle Shapes */}
      <SingleShapeComponent
        shape={shapeOptions?.middle as any}
        textColor={textColor}
      />

      {/* Right Shapes */}
      <SingleShapeComponent
        shape={shapeOptions?.right as any}
        textColor={textColor}
      />
    </div>
  );
}

export function SingleShapeComponent({
  shape = Shape.Empty,
  textColor = 'text-white',
}: {
  shape?: Shape | null;
  textColor?: string;
}) {
  return (
    <Fragment>
      {shape === Shape.Square && <Square className={shapeClassName} />}
      {shape === Shape.Circle && (
        <Circle className={shapeClassName} textColor={textColor} />
      )}
      {shape === Shape.Left && <HalfsquareLeft className={shapeClassName} />}
      {shape === Shape.Right && <HalfsquareRight className={shapeClassName} />}
      {shape === Shape.Up && <HalfsquareUp className={shapeClassName} />}
      {shape === Shape.Down && <HalfsquareDown className={shapeClassName} />}
      {shape === Shape.Empty && <div className={shapeClassName} />}
    </Fragment>
  );
}
