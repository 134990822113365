export const Logo = () => {
  return (
    <div className="h-[30px] w-auto">
      <svg width="136" height="40" viewBox="0 0 136 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M65.0083 2.15726C68.0673 2.15726 70.729 2.86251 72.9986 4.27562C75.2682 5.68873 77.0185 7.67226 78.2546 10.2314C79.4881 12.7906 80.1061 15.5908 80.1061 18.6297C80.1061 21.6685 79.4932 24.5051 78.265 27.0487C77.0367 29.5923 75.2786 31.5732 72.9883 32.9837C70.6979 34.3968 68.0388 35.1021 65.0083 35.1021C61.9778 35.1021 59.3265 34.3994 57.0491 32.9941C54.7743 31.5888 53.0189 29.6156 51.7828 27.0694C50.5467 24.5258 49.9313 21.7126 49.9313 18.6297C49.9313 13.8692 51.2998 9.93583 54.0342 6.8244C56.7686 3.71297 60.4275 2.15726 65.0083 2.15726ZM54.1095 18.6297C54.1095 20.9658 54.5354 23.1464 55.3897 25.1663C56.2441 27.1887 57.5009 28.76 59.1577 29.8853C60.8171 31.0106 62.7647 31.5732 65.0057 31.5732C68.3789 31.5732 71.0407 30.3572 72.9857 27.9277C74.9307 25.4982 75.9045 22.3971 75.9045 18.6271C75.9045 14.8571 74.9436 11.8105 73.0194 9.35762C71.0952 6.90737 68.4101 5.68095 64.9641 5.68095C61.5182 5.68095 58.937 6.91515 57.005 9.38095C55.0729 11.8468 54.1069 14.9297 54.1069 18.6271L54.1095 18.6297ZM99.1744 2.90919C102.059 2.90919 104.358 3.68964 106.066 5.25313C107.775 6.81662 108.629 9.13204 108.629 12.1994C108.629 15.2667 107.775 17.6003 106.066 19.156C104.358 20.7117 102.161 21.4896 99.4756 21.4896H89.3533V34.3502H85.175V2.90919H99.1744ZM98.011 17.9633C100.005 17.9633 101.579 17.494 102.727 16.5554C103.875 15.6168 104.451 14.1648 104.451 12.1994C104.451 10.234 103.885 8.8209 102.75 7.86673C101.615 6.91256 99.9508 6.43547 97.7539 6.43547H89.3533V17.9607H98.011V17.9633ZM135.553 2.90919V6.43547H125.044V34.3476H120.865V6.43547H110.356V2.90919H135.556H135.553Z"
          className="fill-text_color" />
        <path
          d="M24.1762 3.79853L17.5855 0L0.428472 9.90212L0 10.1484V30.4531L6.59068 34.2516V13.947L7.01915 13.7007L24.1762 3.79853Z"
          className="fill-text_color" />
        <path
          d="M27.4274 5.74318L10.2703 15.6453L9.85486 15.8968L9.85226 36.1989L16.4429 40V19.6953L16.861 19.4438L34.0181 9.54171L27.4274 5.74318Z"
          className="fill-text_color" />
        <path d="M30.7799 15.1241L19.6526 21.5389L30.7799 27.9562V15.1241Z" className="fill-text_color" />
      </svg>
    </div>
  );
};
