import * as React from 'react';

interface SvgProps extends React.SVGProps<SVGSVGElement> {
  [key: string]: any
}

const HalfsquareRight: React.FC<SvgProps> = (props: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25"
    fill="none"
    {...props}
  >
    <path className="fill-shapes-path" d="M.18 0h12.47C19.36 0 25 5.6 25 12.5S19.36 25 12.65 25H.18z" />
  </svg>
);


export default HalfsquareRight;
