import * as React from 'react';

interface SvgProps extends React.SVGProps<SVGSVGElement> {
  [key: string]: any
}

const HalfsquareLeft: React.FC<SvgProps> = (props: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25"
    fill="none"
    {...props}
  >
    <path className="fill-shapes-path" d="M25.82 25H13.35C6.46 25 .88 19.4.88 12.5S6.46 0 13.35 0h12.47z" />
  </svg>
);


export default HalfsquareLeft;
