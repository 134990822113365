'use client';
import { Site } from '@apps/site';
import { GlobalPartsFragment, SettingsPartsFragment } from '@generated/types';
import { MenuLink } from '@libs/ui/utils/MenuLink';
import SubMenuSingle from '@libs/ui/utils/SubMenuSingle';
import { useEffect, useState } from 'react';
import { tinaField } from 'tinacms/dist/react';
import { RecursiveOmit } from '../../Blocks/AnimationText';
import { SafeLink } from '../../utils/safe-link';
import { getArrowString } from '../NavBottom';
import { Logo } from './Logo';
import { LogoCosmetics } from './LogoCosmetics';

export default function HeaderTop(props: {
  global: RecursiveOmit<GlobalPartsFragment, '__typename'>;
  settings: RecursiveOmit<SettingsPartsFragment, '__typename'>;
  pageId?: string;
  site: Site;
  path?: string;
}) {
  // State for mobile nav visibility
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  // Function to toggle the mobile navigation
  const toggleMobileNav = () => {
    if (isNavVisible) {
      setIsNavVisible(false);
      document.body.classList.remove('overflow-hidden');
    } else {
      setIsNavVisible(true);
      document.body.classList.add('overflow-hidden');
    }
  };

  useEffect(() => {
    setIsNavVisible(false);
    document.body.classList.remove('overflow-hidden');
  }, []);

  // Function to generate lines of dots
  const generateDots = () => {
    const dots = [];

    let topNav;
    let bottomNav;

    // Get the height of the top and bottom navigation
    if (isClient) {
      topNav = document.getElementById('mobile-top');
      bottomNav = document.getElementById('mobile-bottom');
    }

    if (isClient && topNav && bottomNav) {
      const topNavHeight = topNav.offsetHeight;
      const bottomNavHeight = bottomNav.offsetHeight;
      const remainingHeight =
        window.innerHeight - (topNavHeight + bottomNavHeight);

      const numberOfLines = Math.floor((remainingHeight - 70) / 40);
      for (let i = 0; i < numberOfLines; i++) {
        dots.push(
          <div key={i} className="flex justify-start pt-[20px] pb-[20px]">
            <span className="bg-text_color rounded-full w-1 h-1"></span>
          </div>,
        );
      }
    }

    return dots;
  };

  const [visibleMenu, setVisibleMenu] = useState<number | null>(null); // State to track which submenu is visible

  return (
    <>
      <div
        className={`fixed top-0 left-0 right-0 h-[80px] xl:h-[60px] hd:h-[80px] 2k:h-[100px] pt-0 pb-0 w-screen bg-background`}
      ></div>
      <header
        id="header-top"
        className={`fixed top-0 left-0 right-0 z-50 h-[80px] xl:h-[60px] hd:h-[80px] 2k:h-[100px] pt-0 pb-0 w-screen bg-transparent ${props.site === 'main-site' ? 'lg:mix-blend-difference' : ''}`}
      >
        {/* Mobile Nav */}
        <div
          id="mobileNav"
          data-visible={isNavVisible}
          className={`flex flex-col lg:hidden top-0 h-screen w-[50vw] pl-[20px] absolute bg-background transition-right duration-300 z-30 data-[visible=true]:right-0 right-[-50vw]`}
        >
          {/* Top Nav Items */}
          <nav
            data-visible={isNavVisible}
            id="mobile-top"
            className="flex flex-col gh items-start pt-[80px] data-[visible=false]:opacity-0 data-[visible=true]:delay-500 transition-all"
          >
            {props?.settings?.pageLayout?.header?.links
              ?.slice(1)
              ?.map((link, ind) => (
                <div key={`mobileMenuItem#${ind}`} className={`pb-[40px] `}>
                  <SafeLink
                    href={link?.target}
                    className="menu-item flex-shrink-0 text-center group text-text_color small-text relative inline-block hover:underline"
                    style={
                      link?.colorOverride ? { color: link?.colorOverride } : {}
                    }
                  >
                    {link?.text?.replaceAll(' ', '\u00A0')}
                  </SafeLink>
                  {link?.subMenuLinks && link.subMenuLinks?.length > 0 && (
                    <SubMenuSingle
                      data={link.subMenuLinks}
                      hideDots
                      {...{ visibleMenu, setVisibleMenu }}
                    />
                  )}
                </div>
              ))}
          </nav>

          {/* Dots */}
          <div
            data-visible={isNavVisible}
            className="flex-1 overflow-hidden pt-[30px] pb-40px] data-[visible=false]:opacity-0 data-[visible=true]:delay-500 transition-all"
          >
            <div className="p-0">{generateDots()}</div>
          </div>

          {/* Bottom Nav Items */}
          <nav
            data-visible={isNavVisible}
            id="mobile-bottom"
            className="flex flex-col items-start pt-[20px] pb-20 data-[visible=false]:opacity-0 data-[visible=true]:delay-500 transition-all"
          >
            {[
              props?.settings?.pageLayout?.header?.links?.[0],
              ...(props?.settings?.pageLayout?.bottomNavigationBar?.links ||
                []),
            ]?.map((link, index) => (
              <SafeLink
                key={`header-bottom-link-${index}`}
                href={link?.target ?? '#'}
                className="py-1"
                data-tina-field={tinaField(link, 'text')}
                style={
                  link && 'colorOverride' in link && link?.colorOverride
                    ? { color: link?.colorOverride }
                    : {}
                }
              >
                {getArrowString(link?.text?.replaceAll(' ', '\u00A0'))}
              </SafeLink>
            ))}
          </nav>
        </div>

        <div className="container p-0 header-container">
          <div className="contentGrid grid w-full p-0 h-[80px] xl:h-[60px] hd:h-[80px] 2k:h-[100px]">
            <div className="col-span-4 lg:col-span-1 flex items-center justify-between">
              <div className="flex-shrink-0">
                <SafeLink href="/" aria-label="OPT Industries - Home Page">
                  {props.site === 'main-site' ? <Logo /> : <LogoCosmetics />}
                </SafeLink>
              </div>
              {/* Burger Icon or Close Icon */}
              <div
                id="burgerIcon"
                className="flex text-primary lg:hidden h-full w-[50px] cursor-pointer z-40 relative items-center justify-end" // Higher z-index for burger icon
                onClick={toggleMobileNav} // Toggle mobile nav on click
              >
                <div
                  data-visible={isNavVisible}
                  className="leading-none text-sm  data-[visible=true]:-translate-x-[calc(50vw-20px-30px-50px)] -translate-x-2 transition-all duration-300"
                >
                  <span
                    data-visible={isNavVisible}
                    className="data-[visible=true]:opacity-0 transition-all duration-300 data-[visible=true]:delay-300"
                  >
                    <p>1</p>
                    <p>∞</p>
                    <p>+</p>
                  </span>
                </div>
                <svg
                  width="30"
                  height="26"
                  viewBox="0 0 30 26"
                  xmlns="http://www.w3.org/2000/svg"
                  className="fill-current"
                >
                  {/* Top Line */}
                  <line
                    x1="0"
                    y1="1"
                    x2="30"
                    y2="1"
                    data-visible={isNavVisible}
                    className={`transition-transform origin-left duration-300 data-[visible=true]:rotate-[38.5deg] data-[visible=true]:scale-x-150`}
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="square"
                    style={{ transformOrigin: '0 0.5px' }} // Start point as the origin
                  />

                  {/* Middle Line */}
                  <line
                    x1="0"
                    y1="13"
                    x2="30"
                    y2="13"
                    data-visible={isNavVisible}
                    className={`transition-opacity duration-300 data-[visible=true]:opacity-0`}
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="square"
                  />

                  {/* Bottom Line */}
                  <line
                    x1="0"
                    y1="25"
                    x2="30"
                    y2="25"
                    data-visible={isNavVisible}
                    className={`transition-transform origin-left duration-300 data-[visible=true]:-rotate-[38.5deg] data-[visible=true]:scale-x-150`}
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="square"
                    style={{ transformOrigin: '0 25.5px' }} // Start point as the origin
                  />
                </svg>
              </div>
            </div>

            <nav className="hidden lg:flex col-span-3 items-center">
              <div
                className="items-center justify-between font-regular grid grid-cols-3 grid-flow-row w-full xl:gap-[56px] hd:gap-[80px]"
                data-tina-field={tinaField(props?.settings?.pageLayout?.header)}
              >
                <div className={`flex flex-row lg:mr-2.5 xl:mr-0 2k:mr-5`}>
                  <MenuLink
                    {...(props?.settings?.pageLayout?.header?.links?.[0] ?? {})}
                    index={0}
                    path={props?.path}
                  />
                </div>
                <div className={`col-span-2 flex flex-row 2k:ml-2`}>
                  {props?.settings?.pageLayout?.header?.links?.map(
                    (link, idx) =>
                      idx > 0 && (
                        <MenuLink
                          key={`menuLink#${idx}`}
                          {...link}
                          index={idx}
                          path={props?.path}
                        />
                      ),
                  )}
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}
