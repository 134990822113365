'use client';
import Image from 'next/image';
import { CSSProperties } from 'react';
import { breakpoints } from '../Style/settings/breakpoints';

export const parseMediaSizeFromUrl = (url: string) => {
  const urlObj = new URL(url, 'http://example.com');
  const width = urlObj.searchParams.get('width');
  const height = urlObj.searchParams.get('height');
  return {
    width: width ? Number(width) : null,
    height: height ? Number(height) : null,
    src: url?.split('?')?.[0],
  };
};

export function getThumbnailUrl(videoUrl: string): string {
  try {
    const url = new URL(videoUrl); // Parse the URL
    const filename = url.pathname?.split('/')?.slice(-1)?.[0];
    const pathParts = filename.split('.'); // Split the path into name and extension
    const thumbnailPath = `${url.pathname}/Thumbnails/${pathParts[0]}.0000000.jpg`; // Replace extension with .0000000.jpg
    return `${url.origin}/streams${thumbnailPath}`; // Add /streams/ as the prefix
  } catch (error) {
    console.error('Invalid video URL:', videoUrl);
    return ''; // Return empty string if the URL is invalid
  }
}

interface AnyMediaProps {
  source?: string | null;
  className?: string;
  alt?: string | null;
  noDiv?: boolean;
  sizes?: string;
  quality?: number;
  priority?: boolean;
  style?: CSSProperties;
}

type BreakpointKeys = keyof typeof breakpoints;

interface SizesConfig extends Partial<Record<BreakpointKeys, string>> {
  default: string; // Add default as a required property in config
}
export function sizes(config: SizesConfig) {
  // Separate the default size from the breakpoints
  const { default: defaultSize, ...breakpointSizes } = config;

  // Convert breakpoint sizes to an array and sort by ascending size (mobile-first)
  const sortedBreakpoints = Object.entries(breakpointSizes)
    .map(([key, size]) => ({
      key,
      size,
      value: breakpoints[key as BreakpointKeys],
    }))
    .sort((b, a) => {
      const aValue =
        typeof a.value === 'object' ? parseInt(a.value.max) : parseInt(a.value);
      const bValue =
        typeof b.value === 'object' ? parseInt(b.value.max) : parseInt(b.value);
      return aValue - bValue; // Sort in ascending order
    });

  // Construct the sizes string with min-width queries
  const sizesString = sortedBreakpoints
    .map(({ size, value }) => {
      const minWidth = typeof value === 'object' ? value.max : value;
      return `(min-width: ${minWidth}) ${size}`;
    })
    .join(', ');

  // Append the default size at the end
  return `${sizesString}, ${defaultSize}`;
}

const AnyMedia = ({
  source,
  className,
  alt,
  noDiv,
  sizes,
  quality,
  priority,
  ...rest
}: AnyMediaProps) => {
  if (!source) {
    return <div {...{ className, ...rest }} />;
  }
  // Determine the type of the source (YouTube, image, HLS, DASH, MP4)
  const getSourceType = (source: string) => {
    if (source.includes('youtube.com') || source.includes('youtu.be')) {
      return 'youtube' as const;
    } else if (source.includes('.mp4')) {
      return 'mp4' as const;
    } else if (/\.(jpg|jpeg|png|gif|webp)/.test(source)) {
      return 'image' as const;
    }
    return 'image' as const;
  };

  // Parse width and height from query parameters in the imageUrl

  const sourceType = getSourceType(source);

  // Render YouTube, Image, or Video based on detected source type
  if (sourceType === 'youtube') {
    const videoId = source.includes('v=')
      ? source.split('v=')[1]
      : source.split('/').pop();
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;

    return noDiv ? (
      <iframe
        width="100%"
        height="100%"
        src={embedUrl}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="YouTube Video"
        className={className}
        {...rest}
      ></iframe>
    ) : (
      <div className={`youtube-embed-container ${className}`} {...rest}>
        <iframe
          width="100%"
          height="100%"
          src={embedUrl}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="YouTube Video"
        ></iframe>
      </div>
    );
  }

  if (sourceType === 'image') {
    const { width: w, height: h, src } = parseMediaSizeFromUrl(source);

    return noDiv ? (
      <>
        {w && h ? (
          <Image
            src={source}
            alt={alt || 'Media'}
            width={w}
            height={h}
            className={className}
            unoptimized={src?.toLowerCase().endsWith('.gif')}
            sizes={sizes}
            quality={quality || undefined}
            priority={priority || undefined}
            {...rest}
          />
        ) : (
          <Image
            src={source}
            alt={alt || 'Media'}
            fill
            className={className}
            unoptimized={src?.toLowerCase().endsWith('.gif')}
            sizes={sizes}
            quality={quality || undefined}
            priority={priority || undefined}
            {...rest}
          />
        )}
      </>
    ) : (
      <div className={`image-container ${className}`} {...rest}>
        {w && h ? (
          <Image
            src={source}
            alt={alt || 'Media'}
            width={w}
            height={h}
            quality={quality || undefined}
            priority={priority || undefined}
            unoptimized={src?.toLowerCase().endsWith('.gif')}
            sizes={sizes}
          />
        ) : (
          <Image
            src={source}
            alt={alt || 'Media'}
            fill
            style={{ objectFit: 'cover' }}
            sizes={sizes}
            quality={quality || undefined}
            priority={priority || undefined}
            unoptimized={src?.toLowerCase().endsWith('.gif')}
            {...rest}
          />
        )}
      </div>
    );
  }

  if (sourceType === 'mp4' || sourceType === 'webm' || sourceType === 'ogg') {
    const { width: w, height: h, src } = parseMediaSizeFromUrl(source);
    const aspectRatio = w && h && (h / w) * 100;
    const thumbnail = getThumbnailUrl(src);
    return noDiv ? (
      <>
        {w && h ? (
          <video
            className={className}
            playsInline
            autoPlay
            loop
            muted
            preload={priority ? 'auto' : 'none'}
            {...rest}
            {...(w ? { width: w } : {})}
            {...(h ? { height: h } : {})}
            src={source}
          />
        ) : (
          <video
            className={className}
            playsInline
            autoPlay
            loop
            muted
            preload={priority ? 'auto' : 'none'}
            {...rest}
            src={source}
          />
        )}
      </>
    ) : (
      <>
        {w && h ? (
          <div
            className={`${className}`}
            {...rest}
            style={{
              // position: 'relative',
              width: '100%', // Full width of the parent container
              paddingBottom: `${aspectRatio}%`, // Maintain aspect ratio
              overflow: 'hidden',
            }}
          >
            <Image
              src={thumbnail}
              alt={alt || 'Media'}
              width={w}
              height={h}
              quality={60}
              priority={priority || undefined}
              sizes={sizes}
              className="w-full h-full object-cover absolute left-0 right-0 bottom-0 top-0"
            />
            <video
              className="w-full h-full object-cover absolute left-0 right-0 bottom-0 top-0"
              playsInline
              autoPlay
              loop
              muted
              preload={priority ? 'auto' : 'none'}
              {...(w ? { width: w } : {})}
              {...(h ? { height: h } : {})}
              src={source}
            />
          </div>
        ) : (
          <video
            className={className}
            playsInline
            autoPlay
            loop
            muted
            preload={priority ? 'auto' : 'none'}
            {...rest}
            src={source}
          />
        )}
      </>
    );
  }

  return (
    <div className={className} {...rest}>
      Selected media type is not supported
    </div>
  );
};

export default AnyMedia;
