interface FacebookProps {
  color: string; // Tailwind color class (e.g., "fill-blue-500")
  className?: string; // Additional utility classes for responsive display
}

export const Facebook = ({ color, className }: FacebookProps) => {
  return (
    <div className={`h-[30px] w-auto ${className}`}>
      <svg
        width="27"
        height="26"
        viewBox="0 0 27 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.9992 13.0484C26.9992 5.84121 21.158 0 13.9508 0C6.74355 0 0.902344 5.84121 0.902344 13.0484C0.902344 19.1649 5.1176 24.3027 10.8008 25.7146V17.0343H8.10956V13.0484H10.8008V11.3307C10.8008 6.8912 12.809 4.83199 17.1721 4.83199C17.9978 4.83199 19.425 4.9951 20.0111 5.1582V8.76691C19.7053 8.73633 19.1701 8.71594 18.5024 8.71594C16.3617 8.71594 15.5359 9.52637 15.5359 11.6314V13.0484H19.7971L19.0631 17.0343H15.5308V26C21.9939 25.2202 26.9992 19.7204 26.9992 13.0484Z"
          className={color} // Use the passed color prop
        />
      </svg>
    </div>
  );
};
