export const Burger = () => {
  return (
    <div className="h-[30px] w-auto">
      <svg
        width="33"
        height="30"
        viewBox="0 0 33 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M33 0H0.917969V1.89274H33V0Z" className={`fill-primary`} />
        <path
          d="M33 28.1073H0.917969V30H33V28.1073Z"
          className={`fill-primary`}
        />
        <path
          d="M33 13.959H0.917969V15.8517H33V13.959Z"
          className={`fill-primary`}
        />
      </svg>
    </div>
  );
};
