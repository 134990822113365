'use client';

import { ReactNode, useEffect, useState, useTransition } from 'react';
import {
  Controller,
  FieldValues,
  Path,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import { Site } from '@apps/site';
import {
  ArticleQueryQuery,
  PageQueryQuery,
  SettingsPartsFragment,
} from '@generated/types';
import SubmenuSingleIcon from '@libs/ui/utils/Templates/Shapes/SubmenuSingleIcon';
import { TriangleAlert } from 'lucide-react';
import { useFormState } from 'react-dom';
import { BiInfoSquare } from 'react-icons/bi';
import { tinaField } from 'tinacms/dist/react';
import { RecursiveOmit } from '../../Blocks/AnimationText';
import { ContactFormSubmit } from '../../Blocks/ContactForm/actions';
import { State } from '../../Blocks/ContactForm/types';
import { getHubspotCookie } from '../../Blocks/ContactForm/utils';

export default function HubSpotSubscribeForm<T extends FieldValues>({
  settings,
  data,
  path,
  site,
}: {
  settings?: RecursiveOmit<SettingsPartsFragment, '__typename'>;
  data?: RecursiveOmit<
    ArticleQueryQuery['article'] | PageQueryQuery['page'],
    '__typename'
  >;
  path?: string;
  site?: Site;
}) {
  const subscribeFormId = data?.hubspot?.formId || settings?.hubspot?.formId!;
  const [isSubmitting, startTransition] = useTransition();
  const ContactFormSubmitWithProps = ContactFormSubmit.bind(
    null,
    subscribeFormId,
  );

  const [message, formAction] = useFormState<State, FieldValues>(
    ContactFormSubmitWithProps,
    null,
  );

  useEffect(() => {
    if (message?.status === 'success') {
      console.log('form submitted successfully');
    }
    // Set a unique token and timestamp when the component mounts
    const token = Date.now().toString();
    setTimestamp(token);
  }, [message]);

  const onSubmit: SubmitHandler<FieldValues> = (formData) => {
    const hutk = getHubspotCookie();
    const origin =
      site === 'main-site'
        ? 'www.optindustries.com'
        : 'www.cosmetics.optindustries.com';
    // Add hidden field values
    formData.honeypot = honeypot;
    formData.timestamp = timestamp;
    formData.hutk = hutk;
    formData.pageUrl = `${origin}${path?.startsWith('/') ? '' : '/'}${path}`;
    formData.pageName = data?.title;
    startTransition(() => {
      formAction(formData);
    });
  };

  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [isTouched, setIsTouched] = useState<Record<string, boolean>>({});

  const isSubmitted = message?.status === 'success';

  useEffect(() => {
    setFormSubmitted(isSubmitted || false);
  }, [isSubmitted]);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    trigger,
  } = useForm<T>();

  const handleBlurValidation = async (name: string, onBlur?: () => void) => {
    await trigger(name as Path<T>); // Trigger validation for the specified input field
    if (onBlur) onBlur(); // Call the original onBlur handler if provided
    setIsTouched((prev) => ({ ...prev, [name]: true }));
  };

  const handleChangeValidation = (name: string) => {
    setIsTouched((prev) => ({ ...prev, [name]: true }));
  };

  const onInvalidSubmit = async () => {
    // Mark all required fields as touched
  };
  const [timestamp, setTimestamp] = useState('');
  const [honeypot, setHoneypot] = useState('');

  const handleKeyUp = (e: any) => {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)(e);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}
      className="w-full mb-8"
      onKeyUp={(e) => handleKeyUp(e)}
    >
      <div className="flex flex-row justify-center items-center space-x-2 flex-1  w-full border-shade20 border-b-2 mt-3 lg:mt-[-50px] text-[20px] pb-[10px]">
        <div className="w-full">
          <Controller
            name={'email' as Path<T>}
            control={control}
            render={(renderProps) => {
              return (
                <input
                  type="text"
                  id="email"
                  name="Enter your Email"
                  onChange={(e) => {
                    handleChangeValidation('email');
                    renderProps.field.onChange(e);
                  }}
                  onBlur={() =>
                    handleBlurValidation('email', renderProps.field.onBlur)
                  }
                  placeholder={
                    settings?.pageLayout?.footer?.subscribeText ||
                    'Enter your email to join our newsletter *'
                  }
                  data-tina-field={tinaField(
                    settings?.pageLayout?.footer,
                    'subscribeText',
                  )}
                  className="!bg-none !bg-transparent shadow-none  resize-none !rounded-none border-none focus:outline-none focus:ring-0 py-4 lg:py-6 w-full text-shade20 italic"
                />
              );
            }}
          />

          <p className="text-red-500/50 text-xs translate-y-1 lg:h-0 overflow-visible">
            {Object.values(errors).length > 0 &&
              (Object.values(errors)[0]?.message as ReactNode)}
          </p>
        </div>

        <div className="relative mt-0">
          <button
            disabled={isSubmitting}
            type="button"
            onClick={handleSubmit(onSubmit)}
            name="Subscribe to newsletter"
            id="submit-subscription"
          >
            <span className="-mt-[4px]">
              <SubmenuSingleIcon className="h-[24px] w-[16px] md:h-[24px] md:w-[16px] xl:h-[22px] xl:w-[13px] text-text_color" />
            </span>
          </button>
        </div>
      </div>
      {/* Hidden honeypot field */}
      <input
        type="text"
        id="honeypot"
        name="honeypot"
        value={honeypot}
        onChange={(e) => setHoneypot(e.target.value)}
        style={{ display: 'none' }}
      />
      {/* Hidden timestamp token field */}
      <input id="timestamp" type="hidden" name="timestamp" value={timestamp} />

      {message?.status === 'error' && (
        <p className="bg-primary/10 p-4 flex mb-4 mt-1 items-center font-normal text-red-700">
          <TriangleAlert className="mr-2 text-lg text-xs h-4" />
          {message?.message || 'Error subscribing, please try again later'}
        </p>
      )}
      {isFormSubmitted && (
        <p className="bg-primary/10 p-4 flex mb-4 mt-1 items-center">
          <BiInfoSquare className="mr-2 text-lg" />
          Thank you!
        </p>
      )}
    </form>
  );
}
