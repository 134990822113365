interface InstagramProps {
  color: string; // Tailwind color class (e.g., "fill-blue-500")
  className?: string; // Additional utility classes for responsive display
}

export const Instagram = ({ color, className }: InstagramProps) => {
  return (
    <div className={`h-[30px] w-auto ${className}`}>
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.9999 6.33393C9.31011 6.33393 6.33387 9.31016 6.33387 13C6.33387 16.6898 9.31011 19.6661 12.9999 19.6661C16.6898 19.6661 19.666 16.6898 19.666 13C19.666 9.31016 16.6898 6.33393 12.9999 6.33393ZM12.9999 17.3338C10.6155 17.3338 8.66613 15.3903 8.66613 13C8.66613 10.6097 10.6097 8.66618 12.9999 8.66618C15.3902 8.66618 17.3338 10.6097 17.3338 13C17.3338 15.3903 15.3844 17.3338 12.9999 17.3338ZM21.4935 6.06125C21.4935 6.92569 20.7973 7.61609 19.9387 7.61609C19.0742 7.61609 18.3839 6.91989 18.3839 6.06125C18.3839 5.20261 19.0801 4.50641 19.9387 4.50641C20.7973 4.50641 21.4935 5.20261 21.4935 6.06125ZM25.9086 7.6393C25.8099 5.55651 25.3342 3.71159 23.8084 2.19157C22.2884 0.671538 20.4434 0.195805 18.3606 0.0913756C16.214 -0.0304586 9.78004 -0.0304586 7.63344 0.0913756C5.55645 0.190003 3.71153 0.665737 2.18571 2.18576C0.659878 3.70579 0.189946 5.55071 0.0855163 7.63349C-0.0363179 9.7801 -0.0363179 16.2141 0.0855163 18.3607C0.184144 20.4435 0.659878 22.2884 2.18571 23.8084C3.71153 25.3285 5.55065 25.8042 7.63344 25.9086C9.78004 26.0305 16.214 26.0305 18.3606 25.9086C20.4434 25.81 22.2884 25.3343 23.8084 23.8084C25.3284 22.2884 25.8041 20.4435 25.9086 18.3607C26.0304 16.2141 26.0304 9.7859 25.9086 7.6393ZM23.1354 20.664C22.6829 21.8011 21.8068 22.6771 20.6639 23.1354C18.9524 23.8142 14.8913 23.6576 12.9999 23.6576C11.1086 23.6576 7.04167 23.8084 5.33599 23.1354C4.19887 22.6829 3.32283 21.8069 2.8645 20.664C2.18571 18.9525 2.34235 14.8913 2.34235 13C2.34235 11.1087 2.19151 7.04173 2.8645 5.33605C3.31702 4.19893 4.19307 3.32288 5.33599 2.86455C7.04747 2.18576 11.1086 2.34241 12.9999 2.34241C14.8913 2.34241 18.9582 2.19157 20.6639 2.86455C21.801 3.31708 22.6771 4.19313 23.1354 5.33605C23.8142 7.04753 23.6575 11.1087 23.6575 13C23.6575 14.8913 23.8142 18.9583 23.1354 20.664Z"
          className={color}
        />
      </svg>
    </div>
  );
};
