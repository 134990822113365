// actions.ts
'use server';

import { FieldValues } from '@ez-digital/react-hubspot-hook-form';
import { MongoClient } from 'mongodb';
import { headers } from 'next/headers';
import type { State } from './types';
import { getHubSpotFormBase } from './utils';

export const getHubSpotForm = getHubSpotFormBase;

export async function ContactFormSubmit(
  contactFormId: string,
  currentState: State,
  formData: FieldValues,
): Promise<State> {
  try {
    console.log('Submitting hubspot form id: ' + contactFormId);

    const honeypot = formData.honeypot;
    const timestamp = parseInt(formData.timestamp, 10);
    const hutk = formData?.hutk;
    const pageUrl = formData.pageUrl;
    const pageName = formData.pageName;

    // 1. Check honeypot field - it should be empty
    if (honeypot) {
      return { status: 'error', message: 'Invalid form submission detected.' };
    }

    // 2. Check timestamp - reject if the form is filled in too quickly
    const timeSincePageLoad = Date.now() - timestamp;
    if (timeSincePageLoad < 3000) {
      // e.g., less than 3 seconds
      return { status: 'error', message: 'Form submitted too quickly.' };
    }

    // 3. Validate submitted fields against expected fields
    const originalFormData = await getHubSpotFormBase(contactFormId);
    const expectedFields = originalFormData.fieldGroups?.flatMap((group) =>
      'fields' in group ? group.fields : [],
    );
    const expectedFieldsNames = expectedFields?.map((v) => v.name);
    const submittedFieldNames = Object.keys(formData);

    // 2. Detect Missing and Required Fields
    const missingRequiredFields = expectedFields
      .filter((expectedField) => expectedField.required)
      .map((expectedField) => expectedField.name)
      .filter(
        (requiredFieldName) => !submittedFieldNames.includes(requiredFieldName),
      );

    if (missingRequiredFields.length > 0) {
      return {
        status: 'error',
        message: `Missing required fields: ${missingRequiredFields.join(', ')}`,
      };
    }

    const extraFields = submittedFieldNames.filter(
      (fieldName) =>
        !expectedFieldsNames.includes(fieldName) &&
        fieldName !== 'honeypot' &&
        fieldName !== 'hutk' &&
        fieldName !== 'timestamp' &&
        fieldName !== 'pageUrl' &&
        fieldName !== 'pageName',
    );

    if (extraFields.length > 0) {
      return { status: 'error', message: 'Unexpected fields detected.' };
    }

    // 4. Rate limiting - max 3 submissions in 1 minute per IP
    const now = Date.now();
    const oneMinuteAgo = now - 60000;

    const headersList = headers();
    const clientIp =
      headersList.get('x-forwarded-for')?.split(/, /)?.[0] || '121.0.0.1';
    console.log('Form submission client ip: ' + clientIp);

    let client: MongoClient | undefined;
    try {
      const uri = process.env.MONGODB_URI;
      client = uri ? new MongoClient(uri) : undefined;
      await client?.connect();
      const db = client?.db('tinacms'); // Replace 'myDatabase' with your actual database name
      const submissionsCollection = db?.collection('formSubmissions');
      // Create a TTL index on the `timestamp` field (if it doesn't exist)
      await submissionsCollection?.createIndex(
        { timestamp: 1 },
        { expireAfterSeconds: 60 },
      );
      // Fetch recent submissions from this IP in the last minute
      const recentSubmissions =
        clientIp &&
        (await submissionsCollection?.countDocuments({
          clientIp: clientIp,
          timestamp: { $gt: oneMinuteAgo },
        }));

      if (recentSubmissions && recentSubmissions >= 3) {
        // Cleanup db connections
        await client?.close();
        return {
          status: 'error',
          message: 'Too many submissions. Try again later.',
        };
      }

      // Log the current submission in MongoDB
      clientIp &&
        (await submissionsCollection?.insertOne({
          clientIp: clientIp,
          timestamp: now,
        }));
    } catch (e) {
      console.error(String(e));
      await client?.close();
      return {
        status: 'error',
        message: 'Error submitting the form: ' + String(e),
      };
    } finally {
      // Cleanup db connections
      await client?.close();
    }

    // Delete honeypot and timestamp fields from formData
    delete formData.honeypot;
    delete formData.timestamp;
    delete formData.hutk;
    delete formData.pageUrl;
    delete formData.pageName;

    const formattedFields = [];
    for (const [fieldName, fieldValue] of Object.entries(formData) as [
      string,
      string | { label: string; value: string },
    ][]) {
      if (Array.isArray(fieldValue)) {
        formattedFields.push({
          name: fieldName,
          value: fieldValue.join('; '),
        });
      } else if (typeof fieldValue === 'object') {
        formattedFields.push({
          name: fieldName,
          value: fieldValue?.label || '',
        });
      } else {
        formattedFields.push({ name: fieldName, value: fieldValue || '' });
      }
    }

    try {
      const response = await fetch(
        `https://api.hsforms.com/submissions/v3/integration/submit/${process?.env?.HUBSPOT_PORTAL_ID}/${contactFormId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            fields: formattedFields,
            context: {
              ipAddress: clientIp, // Add client IP here
              hutk: hutk, // Include the hubspotutk cookie here
              pageUri: pageUrl, // Include the conversion page URL
              pageName: pageName, // Include the conversion page name (optional but useful)
            },
          }),
        },
      );

      if (!response.ok) {
        const errorData = await response.json();
        console.error(errorData);
        return {
          status: 'error',
          message: errorData.message || 'Submission failed',
        };
      }

      return { status: 'success' };
    } catch (error: any) {
      console.log(error);
      return { status: 'error', message: error.message || 'Unexpected error' };
    }
  } catch (e) {
    console.error(String(e));
    return {
      status: 'error',
      message: 'Error processing the form: ' + String(e),
    };
  }
}
