export const Close = () => {
  return (
    <div className="h-[30px] w-auto">
      <svg
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M33 29.9846L2 0.569336L0.917969 1.87736L32 31.3462L33 29.9846Z"
          className={`fill-primary`}
        />
        <path
          d="M32 0.707764L0.917969 30.0894L2 31.4309L33 1.98218L32 0.707764Z"
          className={`fill-primary`}
        />
        <path
          d="M33 15.1073H0.917969V17H33V15.1073Z"
          className={`fill-primary`}
        />
      </svg>
    </div>
  );
};
