export enum FontSize {
  H1 = 'H1',
  H2 = 'H2',
  H3 = 'H3',
  H4 = 'H4',
  Body = 'Body',
  Caption = 'Caption',
}
export enum FontWeight {
  UltraLight = 'UltraLight',
  Light = 'Light',
  Regular = 'Regular',
  Medium = 'Medium',
  Bold = 'Bold',
}

export enum ElementSize {
  Large = 'Large',
  Small = 'Small',
}

export enum VerticalPosition {
  Top = 'Top',
  Middle = 'Middle',
  Bottom = 'Bottom',
}

export enum Sides {
  Left = 'Left',
  Right = 'Right',
}

export enum GalleryVariant {
  Image3x2 = 'Image 3:2',
  Image1x1 = 'Image 1:1',
}

export enum InfoImageTextVariant {
  ImageLeftTextRight = 'Image Left - Text Right',
  ImageRightTextLeft = 'Image Right - Text Left',
}

export enum InfoTextVariant {
  HeadlineLeftTextRight = 'Headline Left - Text Right',
  HeadlineRightTextLeft = 'Headline Right - Text Left',
}
// Enum for Text Variant
export enum MediumTeaserTextVariant {
  TextLeft = 'Text Left',
  TextRight = 'Text Right',
}

export enum ThreeColImageOneColTextLayout {
  ImageLeftTextRight = 'Image Left Text Right',
  ImageRightTextLeft = 'Image Right Text Left',
}
export enum TwoColImageTwoColTextLayout {
  ImageLeftTextRight = 'Image Left Text Right',
  ImageRightTextLeft = 'Image Right Text Left',
}

export enum TwoColImageTwoColTextVariant {
  Large = 'Large',
  Small = 'Small',
  HalfFullscreen = 'HalfFullscreen',
}
export enum ColImageTextVariant {
  TwoColumns = '2 Columns',
  FourColumns = '4 Columns',
}

export enum MediaAspectRatio {
  M1x1 = '1:1',
  M3x2 = '3:2',
  M2x3 = '2:3',
  M2x4 = '2:4',
}

export enum Shape {
  Square = 'Square',
  Circle = 'Circle',
  Left = 'Left',
  Right = 'Right',
  Up = 'Up',
  Down = 'Down',
  Empty = 'Empty',
}
export enum ElementPositioningOptions {
  Absolute = 'absolute',
  Fixed = 'fixed',
  Relative = 'relative',
  Sticky = 'sticky',
}

export enum TwitterCardOptions {
  Summary = 'summary',
  SummaryLargeImage = 'summary_large_image',
  App = 'app',
  Player = 'player',
}
export enum SubmenuEntryType {
  ListText = 'List Text',
  SubmenuSingle = 'Submenu Single',
}
