import { HubSpotFormResponse } from './types';

export const getHubSpotFormBase = async (
  formId: string,
  cache: 'force-cache' | 'no-cache' = 'no-cache',
): Promise<HubSpotFormResponse> => {
  // Validate and sanitize formId to match UUID format
  const uuidRegex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

  if (!uuidRegex.test(formId)) {
    throw new Error('Invalid form ID.');
  }

  try {
    const res = await fetch(
      `https://api.hubapi.com/marketing/v3/forms/${formId}`,
      {
        cache,
        headers: {
          Authorization: `Bearer ${process?.env.HUBSPOT_TOKEN}`,
        },
      },
    );
    if (!res.ok) throw new Error(`HTTP error! status: ${res.status}`);
    const data = await res.json();
    return {
      fieldGroups: data?.fieldGroups || [],
      submitButtonText: data?.displayOptions?.submitButtonText || '',
    };
  } catch (error) {
    if (error instanceof Error) {
      // console.error(error);
      throw new Error(error.message);
    } else {
      // console.error(error);
      throw new Error('An unknown error occurred.');
    }
  }
};

export const getHubspotCookie = () => {
  const name = 'hubspotutk=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookies = decodedCookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return '';
};
