import { Site } from '@apps/site';
import { GlobalPartsFragment, SettingsPartsFragment } from '@generated/types';
import { RefObject } from 'react';
import { tinaField } from 'tinacms/dist/react';
import { RecursiveOmit } from '../../Blocks/AnimationText';
import ShapesComponent from '../../shared/shapes';
import { SafeLink } from '../../utils/safe-link';
import { useScroll } from '../../utils/scroll-context';

const generateLines = () => {
  const lines = [];

  for (let i = 0; i < 32; i++) {
    lines.push(
      <div key={i} className="justify-start pt-[10px] pb-[10px] font-regular">
        <span className="pr-[10px]">l</span>
      </div>,
    );
  }

  return lines;
};

export const getArrowString = (name?: string | undefined | null) => {
  return <>{name?.endsWith('->') ? <>{name}</> : name}</>;
};

export default function NavBottom(props: {
  global: RecursiveOmit<GlobalPartsFragment, '__typename'>;
  settings: RecursiveOmit<SettingsPartsFragment, '__typename'>;
  footerRef: RefObject<HTMLElement>;
  pageId?: string;
  site: Site;
}) {
  const scroll = useScroll();
  const isDown =
    props.footerRef?.current?.offsetTop &&
    props.footerRef?.current?.offsetTop > window.innerHeight &&
    scroll + window.innerHeight >= props.footerRef?.current?.offsetTop;

  return (
    <>
      <div
        style={{
          ...({
            '--offset-top': (props.footerRef?.current?.offsetTop || 0) + 'px',
          } as any),
        }}
        data-down={!!isDown}
        className={`data-[down=true]:absolute data-[down=true]:top-[var(--offset-top)] data-[down=true]:-translate-y-[100%] data-[down=false]:fixed pointer-events-none lg:pointer-events-auto bottom-[-1px] lg:bottom-0 left-0 right-0 h-[80px] flex pt-0 pb-[40px] lg:pb-0 w-screen bg-background`}
      ></div>
      <nav
        id="header-bottom"
        role="navigation"
        style={{
          ...({
            '--offset-top': (props.footerRef?.current?.offsetTop || 0) + 'px',
          } as any),
        }}
        data-down={!!isDown}
        className={`data-[down=true]:absolute data-[down=true]:top-[var(--offset-top)] data-[down=true]:-translate-y-[100%] data-[down=false]:fixed pointer-events-none lg:pointer-events-auto  bottom-[-1px] lg:bottom-0 left-0 right-0 z-50 h-[80px] flex pt-0 pb-[40px] lg:pb-0 w-screen bg-transparent ${props.site === 'main-site' ? 'mix-blend-difference' : ''}`}
      >
        <div className="container p-0 header-container lg:contentGrid flex lg:grid lg:w-full h-[80px] text-text_color">
          <div className="col-span-4 lg:col-span-1 flex items-center justify-start w-1/2 lg:w-full">
            <div className="flex overflow-hidden">{generateLines()}</div>
          </div>
          <div className="col-span-1 flex items-center justify-end w-1/2 lg:w-full">
            {/* ShapeSwitcher Component */}
            {props?.settings?.pageLayout?.bottomNavigationBar?.shapeOptions && (
              <div
                data-tina-field={tinaField(
                  props?.settings?.pageLayout?.bottomNavigationBar,
                  'shapeOptions',
                )}
              >
                <ShapesComponent
                  shapeOptions={
                    props?.settings?.pageLayout?.bottomNavigationBar
                      ?.shapeOptions
                  }
                />
              </div>
            )}
          </div>
          <div className={`col-span-2 hidden items-center lg:flex`}>
            <div
              className="menu justify-between flex w-full align-middle items-center font-regular"
              data-tina-field={tinaField(
                props?.settings?.pageLayout?.bottomNavigationBar,
              )}
            >
              {props?.settings?.pageLayout?.bottomNavigationBar?.links?.map(
                (link, index) =>
                  link?.asButton ? (
                    <SafeLink
                      key={`header-bottom-link-${index}`}
                      href={link?.target ?? '#'}
                      className="button light tertiary-button !bg-primary font-regular hover:no-underline"
                      data-tina-field={tinaField(link, 'text')}
                      style={{
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        paddingTop: '9px',
                        paddingBottom: '6px',
                        textDecoration: 'none !important',
                        border: 'none',
                      }}
                    >
                      {getArrowString(link?.text)}
                    </SafeLink>
                  ) : (
                    <SafeLink
                      key={`header-bottom-link-${index}`}
                      href={link?.target ?? '#'}
                      className="flex align-middle items-center justify-center relative"
                      data-tina-field={tinaField(link, 'text')}
                    >
                      {getArrowString(link?.text)}
                    </SafeLink>
                  ),
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
