import * as React from 'react';

interface SvgProps extends React.SVGProps<SVGSVGElement> {
  [key: string]: any
}

const HalfsquareUp: React.FC<SvgProps> = (props: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25"
    fill="none"
    {...props}
  >
    <path
      className="fill-shapes-path"
      fillRule="evenodd"
      d="M.79 24.97V12.5C.79 5.61 6.39.03 13.29.03s12.5 5.58 12.5 12.47v12.47z"
      clipRule="evenodd"
    />
  </svg>
);

export default HalfsquareUp;
