interface LinkedInProps {
  color: string;
  className?: string;
}

export const LinkedIn = ({ color, className }: LinkedInProps) => {
  return (
    <div className={`h-[30px] w-auto ${className}`}>
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.1429 0H1.85134C0.829911 0 0 0.841518 0 1.87455V24.1254C0 25.1585 0.829911 26 1.85134 26H24.1429C25.1643 26 26 25.1585 26 24.1254V1.87455C26 0.841518 25.1643 0 24.1429 0ZM4.00446 22.2857V9.87768H7.86384V22.2857H4.00446ZM5.93125 8.18304C4.69509 8.18304 3.69687 7.17902 3.69687 5.94866C3.69687 4.7183 4.69509 3.71429 5.93125 3.71429C7.16161 3.71429 8.16562 4.7183 8.16562 5.94866C8.16562 7.18482 7.16741 8.18304 5.93125 8.18304ZM22.3031 22.2857H18.4496V16.25C18.4496 14.8107 18.4205 12.9594 16.4473 12.9594C14.4393 12.9594 14.1317 14.5263 14.1317 16.1455V22.2857H10.2781V9.87768H13.975V11.5723H14.0272C14.5437 10.5973 15.8031 9.57009 17.6777 9.57009C21.5777 9.57009 22.3031 12.1411 22.3031 15.4839V22.2857Z"
          className={color} // Use the passed color prop
        />
      </svg>
    </div>
  );
};
