export const breakpoints = {
  xs: '428px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1280px',
  hd: '1920px',
  '2k': '2560px',
  '-xs': { max: '428px' },
  '-sm': { max: '576px' },
  '-md': { max: '768px' },
  '-lg': { max: '992px' },
  '-xl': { max: '1280px' },
  '-hd': { max: '1920px' },
} as const;
