interface YouTubeProps {
  color: string; // Tailwind color class (e.g., "fill-red-500")
  className?: string; // Additional utility classes for responsive display
}

export const YouTubeIcon = ({ color, className }: YouTubeProps) => {
  return (
    <div className={`h-[30px] w-auto ${className}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 22"
        className="w-full h-full"
      >
        <defs>
          <style>
            {`.cls-1 { fill: #fff; }`}
          </style>
        </defs>
        <polygon className={color} points="9.57 13.12 13.23 11.01 9.57 8.9 9.57 13.12" />
        <path
          className={color}
          d="M11,0C4.92,0,0,4.92,0,11s4.92,11,11,11,11-4.92,11-11S17.08,0,11,0ZM17.71,14.46c-.16.62-.64,1.08-1.24,1.25-1.09.3-5.47.3-5.47.3,0,0-4.38,0-5.47-.3-.6-.16-1.08-.63-1.24-1.25-.29-1.12-.29-3.45-.29-3.45,0,0,0-2.33.29-3.45.16-.62.64-1.1,1.24-1.27,1.09-.3,5.47-.3,5.47-.3,0,0,4.38,0,5.47.3.6.16,1.08.65,1.24,1.27.29,1.12.29,3.45.29,3.45,0,0,0,2.33-.29,3.45Z"
        />
      </svg>
    </div>
  );
};
