import * as React from 'react';

interface SvgProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
  textColor?: string;
  shapeBoxshadow?: boolean;
  [key: string]: any;
}

const Square: React.FC<SvgProps> = ({
  className,
  textColor = 'text-white',
  shapeBoxshadow = false,
  ...props
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={25}
    fill="none"
    viewBox="0 0 25 25"
    {...props}
    className={className || ''}
  >
    <path
      className="fill-shapes-path"
      d="M25.82.005H.88v24.99h24.94z"
    />
  </svg>
);

export default Square;
