import { Site } from '@apps/site';
import type {
  ArticleQueryQuery,
  GlobalPartsFragment,
  PageQueryQuery,
  SettingsPartsFragment,
} from '@generated/types';
import { Toaster } from '@libs/ui/shared/toaster';
import { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { RecursiveOmit } from '../Blocks/AnimationText';
import { ScrollProvider } from '../utils/scroll-context';
import Footer from './Footer';
import HeaderTop from './HeaderTop';
import NavBottom from './NavBottom';

export const Layout = (
  props: { children?: ReactNode } & {
    global: RecursiveOmit<GlobalPartsFragment, '__typename'>;
    settings: RecursiveOmit<SettingsPartsFragment, '__typename'>;
    data?: RecursiveOmit<
      ArticleQueryQuery['article'] | PageQueryQuery['page'],
      '__typename'
    >;
    path?: string;
  } & { pageId?: string; site: Site },
) => {
  const footerRef = useRef<HTMLElement>(null);
  const [footerOffsetTop, setFooterOffsetTop] = useState<number | undefined>(
    footerRef?.current?.offsetTop,
  );

  useEffect(() => {
    const updateOffsetTop = () => {
      if (footerRef.current) {
        setFooterOffsetTop(footerRef.current.offsetTop);
      }
    };
    updateOffsetTop();
    window.addEventListener('resize', updateOffsetTop);
    return () => {
      window.removeEventListener('resize', updateOffsetTop);
    };
  }, []);

  return (
    <Fragment>
      <ScrollProvider>
        <div className="h-full relative overflow-hidden">
          <HeaderTop {...props} />
          <NavBottom
            {...{
              ...props,
              footerRef,
            }}
          />
          <main className="w-full space-y-4 pt-0 pb-0 flex-1 [overflow-anchor:none]">
            {props.children}
            <Toaster />
          </main>
          <footer className="w-full" ref={footerRef}>
            <Footer {...props} data={props?.data} path={props?.path} />
          </footer>
        </div>
      </ScrollProvider>
    </Fragment>
  );
};
