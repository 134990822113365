import { Link } from '@apps/i18n/routing';
import { LinkProps } from 'next/link';
import { CSSProperties, HTMLAttributeAnchorTarget, ReactNode } from 'react';

export function SafeLink({
  target = undefined,
  ...props
}: (
  | LinkProps
  | (Omit<LinkProps, 'href'> & ({ href?: undefined } | { href: null }))
) & {
  children: ReactNode | ReactNode[];
  className?: string;
  target?: HTMLAttributeAnchorTarget;
  style?: CSSProperties;
}) {
  return props?.href !== undefined && props?.href !== null && !!props.href ? (
    String(props.href).startsWith('http') ? (
      <a
        {...props}
        href={String(props.href)}
        target={target}
        rel={target === '_blank' ? 'noopener noreferrer' : undefined}
      >
        {props.children}
      </a>
    ) : (
      <Link {...(props as any)} target={target}>
        {props.children}
      </Link>
    )
  ) : (
    <span className={props.className || undefined} {...props}>
      {props.children}
    </span>
  );
}
