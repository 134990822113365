interface CorporateProps {
  color: string; // Tailwind color class (e.g., "fill-blue-500")
}

export const Corporate = ({ color }: CorporateProps) => {
  return (
    <div className="h-[30px] w-auto">
      <svg
        width="16"
        height="12"
        viewBox="0 0 16 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.6657 1.87759C15.4817 1.13853 14.9395 0.556469 14.2511 0.358938C13.0033 0 8 0 8 0C8 0 2.99669 0 1.7489 0.358938C1.06052 0.5565 0.518349 1.13853 0.334336 1.87759C0 3.21719 0 6.01213 0 6.01213C0 6.01213 0 8.80706 0.334336 10.1467C0.518349 10.8857 1.06052 11.4435 1.7489 11.6411C2.99669 12 8 12 8 12C8 12 13.0033 12 14.2511 11.6411C14.9395 11.4435 15.4817 10.8857 15.6657 10.1467C16 8.80706 16 6.01213 16 6.01213C16 6.01213 16 3.21719 15.6657 1.87759ZM6.36363 8.54972V3.47453L10.5454 6.01219L6.36363 8.54972Z"
          className={color} // Dynamic Tailwind color class
        />
      </svg>
    </div>
  );
};
